import React from 'react'
import { css } from 'linaria'
import { useHubspotForm } from '@aaronhayes/react-use-hubspot-form'
import Layout from '~/components/Common/Layout'
import { STYLE } from '~/utils/constant'
import { rem } from '~/utils/helper'

const inquiryStyle = css`
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;

  & > .wrapper {
    padding: calc(var(--height-header) + 72px) 24px 48px;

    @media screen and (${STYLE.MEDIA.PC}) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
  }

  & > .wrapper > .body > .title {
    ${STYLE.MIXIN.CONTENT_TITLE};
  }

  & > .wrapper > .body > .text {
    margin-top: 32px;
    padding-left: var(--font-letter-spacing);
    font-size: ${rem(16)};
    font-weight: 400;
    text-align: center;
  }

  & > .wrapper > .body > .content {
    margin-top: 32px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      display: flex;
      justify-content: center;
      max-width: var(--width-max-content);
      margin-top: 96px;
      margin-right: auto;
      margin-left: auto;
    }
  }

  & > .wrapper > .body > .content > .form {
    margin-top: 72px;

    @media screen and (${STYLE.MEDIA.TABLET}) {
      flex-basis: 470px;
      min-width: 470px;
      margin-top: 0;
    }
  }
`

const breadcrumb = [
  {
    label: 'お問い合わせ',
  },
]

const seo = {
  title: 'お問い合わせ',
}

const InquiryPage = () => {
  if (process.env.HUBSPOT_PORTAL_ID) {
    useHubspotForm({
      portalId: process.env.HUBSPOT_PORTAL_ID,
      formId: '197deffe-ebdf-4f72-a1ff-9f80f017c316',
      target: '#hubspot-form',
    })
  }
  return (
    <Layout breadcrumb={breadcrumb} seo={seo}>
      <main className={inquiryStyle}>
        <div className="wrapper">
          <div className="body">
            <h1 className="title">お問い合わせ</h1>
            <p className="text">
              サービスに関するご質問、デモ視聴のご希望など、お気軽にご相談ください。内容確認後、担当者よりご連絡いたします。
            </p>

            <div className="content">
              <div id="hubspot-form" className="form"></div>
            </div>
          </div>
        </div>
      </main>
    </Layout>
  )
}

export default InquiryPage
